import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to my test site.</p>
    <p>I'm testing out structured data.</p>
    <div style={{ maxWidth: `900px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link><br/>
    <StyledLink href="https://github.com/Endorel" target="_blank">Github</StyledLink>
  </Layout>
)

export default IndexPage;

const LinkContainer = styled.div`
  margin: 0 auto;
  width: 50%;
  background: #ddd;
  text-align: center;
`;

const StyledLink = styled.a`
  color: #999;
  font-family: Arial, Helvetica, sans-serif;
`;

